<template>
  <!-- 增加修改货品页面 -->
  <el-dialog :title="Title" :visible.sync="huoPinChecked" top="50px" @close="close">
    <el-form ref="form" :rules="rules" label-position="left" label-width="100px" input-width="100px" :model="StorageHuoPin">
      <el-row>
        <el-col :span="12" style="margin-bottom:-15px">
          <el-form-item :label="$t('StorageCategory.type')" prop="CategoryName">
            <el-input :placeholder="$t('StorageCategory.type')" v-model="StorageHuoPin.CategoryName" @input="changeDraft($event, 'CategoryName', 0)" readonly
              class="input-with-select" style="width: 70%">
              <el-button slot="append" icon="el-icon-thumb" @click="TypeClick(StorageHuoPin)"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.HuoPinCode')"> <!--货品编号-->
            <el-input :placeholder="this.$t('StorageCategory.HuoPinCode')" maxlength="32" show-word-limit v-model="StorageHuoPin.HuoPinCode"
              @input="changeDraft($event,'HuoPinCode',0)">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.HuoPinName')" prop="HuoPinName"><!--货品名称-->
            <el-input :placeholder="$t('StorageCategory.HuoPinName')" maxlength="32" show-word-limit v-model="StorageHuoPin.HuoPinName" @input="changeDraft($event,'HuoPinName',0)">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.HuoPinModels')"><!--规格型号-->
            <el-input :placeholder="$t('StorageCategory.HuoPinModels')" maxlength="32" show-word-limit v-model="StorageHuoPin.HuoPinModels"
              @input="changeDraft($event,'HuoPinModels',0)">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.HuoPinBarCode')"><!--货品条码-->
            <el-input :placeholder="$t('StorageCategory.HuoPinBarCode')" maxlength="128" show-word-limit v-model="StorageHuoPin.HuoPinBarCode"
              @input="changeDraft($event,'HuoPinBarCode',0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.BrandTrademark')"><!--品牌商标-->
            <el-input :placeholder="$t('StorageCategory.BrandTrademark')" maxlength="32" show-word-limit v-model="StorageHuoPin.BrandTrademark"
              @input="changeDraft($event,'BrandTrademark',0)"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.DefaultPrice')"><!--默认价值-->
            <el-input type="number" :placeholder="$t('StorageCategory.DefaultPrice')" maxlength="8" show-word-limit v-model="StorageHuoPin.DefaultPrice"
              @input="changeDraft($event,'DefaultPrice',0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.CalcUnit')"><!--计数单位-->
            <el-input :placeholder="$t('StorageCategory.CalcUnit')" maxlength="8" show-word-limit v-model="StorageHuoPin.CalcUnit" @input="changeDraft($event,'CalcUnit',0)">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.SafeStockLowerLimit')"><!--安全库存下限-->
            <el-input type="number" :placeholder="$t('StorageCategory.SafeStockLowerLimit')" maxlength="8" show-word-limit v-model="StorageHuoPin.SafeStockLowerLimit"
              @input="changeDraft($event,'SafeStockLowerLimit',0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.SafeStockUpperLimit')"><!--安全库存上限-->
            <el-input type="number" :placeholder="$t('StorageCategory.SafeStockUpperLimit')" maxlength="8" show-word-limit v-model="StorageHuoPin.SafeStockUpperLimit"
              @input="changeDraft($event,'SafeStockUpperLimit',0)"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.HuoPinRemark')"><!--货品描述-->
            <el-input type="textarea" class="textarea" rows="3" maxlength="512" show-word-limit v-model="StorageHuoPin.HuoPinRemark"
              @input="changeDraft($event,'HuoPinRemark',0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageCategory.HuoPinImage')"><!--货品图片-->
            <input ref="imgfiles" :placeholder="$t('StorageCategory.HuoPinImage')" type="file" @change="selImg($event)" style="width: 0;height: 0;"
              accept="image/jpg,image/jpeg,image/png" />
            <div v-show="!addUserImg" @click="upImg()" class="avatars">+</div>
            <img @click="upImg()" v-if="addUserImg" :src="addUserImg || StorageHuoPin.HuoPinImage" class="avatar" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" class="button_info" style="margin-right: 7px;">{{$t('StorageCategory.cancel')}}</el-button><!--取消-->
      <!-- 添加货品 -->
      <el-button type="primary" class="button_primary" :loading="loading" @click="appendHuoPin()">{{$t('StorageCategory.confirm')}}</el-button><!--确定-->
    </div>
    <!-- 种类组件 -->
    <SelectCategory v-if="CategoryType" :type="CategoryType" @close="CategoryType = false" @addMember="addCategory" :DataList="TypeList"></SelectCategory>
  </el-dialog>
</template>
  
  <script>

import {
  successTips,
  // 左
  GetStorageCategoryTree,
  GetStorageCategory,
  // 右 
  GetStorageHuoPin,
  AddStorageHuoPin,
  UpdateStorageHuoPin,
  // 文件
  upLoadFile,
  GetExportColumn,
} from "@/api/user";
import mixins from '@/mixins/index';
import draft from '@/mixins/draft';
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  props: {
    show: {
      type: Boolean,
    },
    HuoPinOpertion: {
      type: Object
    },
    HuoPinID: {
      type: String,
    }, 
    TypeID: {
      type: [String, Number],
      default: () => {
        return '';
      }
    },
    TypeName: {
      type: String,
    }, 
    Title: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  name: "SettingView",
  components: {},
  mixins: [mixins, draft],
  data() {
    return {
      drawer: false,
      tabs: 0,
      menutab: 0,
      ID: "",
      filterText: "",
      icon: '',

      HuoPinStatus: false,
      updatetype: false,
      currentPage: 1,

      CategoryText: "",
      checked: true,
      dialogFormVisible: false,
      huoPinChecked: false,
      HuoPinChecked: false,
      addChecked: true,
      loading: false,
      tableData: [],
      CategoryList: [],
      CategoryID: '',
      StorageCategory: {
        CategoryID: '',
        CompanyID: '',
        CategoryCode: '',
        CategoryName: '',
        ParentCategoryID: '',
        ParentCategoryID1: '',
        ParentCategoryID2: '',
        ParentCategoryID3: '',
        ParentCategoryID4: '',
        ParentCategoryID5: '',
        ParentCategoryID6: '',
        ParentCategoryID7: '',
        ParentCategoryID8: '',
        ParentCategoryID9: '',
        ParentCategoryID10: '',
        CategoryLevel: '',
        CategoryStatus: '',
        CategoryRemark: '',
        UpdateTime: '',
        CreateTime: ''
      },
      dataTree: [],
      HuoPindata: [],
      form: {
        PageIndex: 0,
        PageSize: 20,
        SortTitle: '',
        SortType: '',
        StartTime: '',
        EndTime: '',
        SearchName: '',
        HuoPinCode: '',
        HuoPinName: '',
        CalcUnit: '',
        CategoryID: 0,
        CategoryName: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        DefaultPrice: '',
        HuoPinStatus: '',
        HuoPinStatusName: ''
      },
      StorageHuoPin: {
        ID: '',
        ParentCategoryID: '',
        CompanyID: '',
        CategoryID: '',
        CategoryName: '',
        HuoPinCode: '',
        HuoPinName: '',
        HuoPinImage: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        DefaultPrice: '',
        CalcUnit: '',
        SafeStockLowerLimit: '',
        SafeStockUpperLimit: '',
        FactStock: '',
        RFIDStock: '',
        ElseStock: '',
        HuoPinStatus: '',
        HuoPinRemark: '',
        UpdateTime: '',
        CreateTime: '',
        SearchName: '',
        PageSize: '20',
        PageIndex: 0,
      },
      TotalCount: 0,
      addUserImg: '',
      rules: {
        CategoryName: [
          { required: true, message: this.$t('StorageCategory.PleaseEnter'), trigger: 'blur' }
        ],
        HuoPinName: [
          { required: true, message: this.$t('StorageCategory.PleaseEnter'), trigger: 'blur' }
        ]
      },
      options: [],
      upfile: null,
      multipleSelection: [],
      upload: false,
      CategoryType: false,
      uptype: '',
      exportColumn: [],
      TypeList: [],
      ExportType: '',
      errString: "",
      errUrl: '',
      viewtype: '',
      draftTitle: ['StorageHuoPin']
    };
  },
  mounted() {
    if (this.HuoPinOpertion.Opertion == 'Update') {
      GetStorageHuoPin({ HuoPinID: this.HuoPinID }).then((res) => {
        if (res.Code == 0) {
          this.StorageHuoPin = res.Data;
          this.addUserImg = res.Data.HuoPinImage;
          GetStorageCategory({ CategoryID: this.StorageHuoPin.CategoryID }).then((data) => {
            if (data.Code == 0) {
              this.CategoryName = data.Data.CategoryName;
            }
          });

        }
      });
    } else { 
      if (this.TypeID != 0) {
        this.StorageHuoPin.CategoryID = this.TypeID;
        this.StorageHuoPin.CategoryName = this.TypeName;
      } 
    }
    if (this.state) {
      this.StorageHuoPin = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
    }
    this.getStorageCategory();
    this.huoPinChecked = this.show;
  },

  methods: {
    //种类组件事件
    addCategory(e) {
      this.StorageHuoPin.CategoryID = e.CategoryID;
      this.StorageHuoPin.CategoryName = e.CategoryName;
      this.changeDraft(e.CategoryID, 'CategoryID', 0);
      this.changeDraft(e.CategoryName, 'CategoryName', 0);
      this.$refs['form'].clearValidate(['CategoryName']);  //移除表单某个字段的校验 
      this.CategoryType = false;
    },
    //种类点击事件
    TypeClick(data) {
      this.TypeList = [];
      if (data) {
        this.CategoryType = true;
        this.TypeList.push(data.CategoryID);
      }
    },
    //种类增删改查区域
    getStorageCategory() {
      GetStorageCategoryTree().then((res) => {
        if (res.Code == 0) {
          this.CategoryList = res.Data;
        }
      });
    },
    // 选中种类
    StorageCategoryClick(e) {
      this.StorageHuoPin.CategoryID = e.id;
      this.StorageHuoPin.CategoryName = e.label;
    },
    getColumn() {
      GetExportColumn({ ExportName: this.viewtype }).then(res => {
        if (res.Code == 0) {
          this.exportColumn = res.Data;
          this.upload = true;
        }
      })
    },
    //清空表格数据
    close() {
      this.draftState[this.draftIndex] = false;
      this.$emit("close");
    },

    //照片区域
    upImg() {
      this.$refs.imgfiles.click();
    },
    selImg(e) {
      if (e.target.files.length == 0) {
        return
      }
      let file = e.target.files[0];
      if (this.LimitaTionImg(file)) {
        return;
      }
      this.upfile = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.addUserImg = e.target.result;
      }
    },
    HideDialog() {

    },
    reset() {
      for (let i in this.StorageHuoPin) {
        this.StorageHuoPin[i] = ''
      }
    },
    async appendHuoPin() {
      let state = false;
      this.$refs.form.validate((valid) => {
        if (valid) {
          state = true;
        }
      })
      if (!state) return
      this.loading = true;
      if (this.upfile) {
        let formData = new FormData();
        formData.append('file', this.upfile);
        let ret = await upLoadFile(formData);
        if (ret.Code == 0) {
          this.StorageHuoPin.HuoPinImage = ret.Data;
        }
      }
      if (this.HuoPinOpertion.Opertion == 'Add') {
        AddStorageHuoPin(this.StorageHuoPin).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.huoPinChecked = false; //弹窗页面是否开启
              this.$emit('message', res.Code);
              this.reset();
              window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
              this.draftState[this.draftIndex] = false;
            }
          }
        );
      } else if (this.HuoPinOpertion.Opertion == 'Update') {
        UpdateStorageHuoPin(this.StorageHuoPin).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.$emit('message', res.Code);
              this.huoPinChecked = false; //弹窗页面是否开启
              this.updatetype = true;
              this.reset();
              window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
              this.draftState[this.draftIndex] = false;
            }
          }
        );
      }
      this.upfile = null;
      this.loading = false;
    },
  },
};
  </script>
  
  <style lang="scss" scoped>
.el-form-item__content {
  line-height: 20px !important;
}
.treeTable {
  overflow: auto;
  max-height: 650px;
}
.inputType {
  width: 100%;
}

.SearchName {
  margin-right: 0% !important;
}

.el-input {
  width: 75% !important;
  margin-right: 8%;
}

._dropdown {
  height: 20px;
}

.el-select {
  width: 75% !important;
  margin-right: 8%;
}

.textarea {
  width: 75% !important;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

.el-icon-plus {
  border: 1px dashed;
}

._filter_catagory {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.avatars {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  line-height: 1;
  background-color: #ffffff;
  border: 1px dashed #ccc;
  cursor: pointer;
  margin-top: -40px;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  margin-top: -40px;
  width: 72px;
  height: 72px;
  display: block;
}


 

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 72px;
  height: 72px;
  line-height: 120px;
  text-align: center;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

 

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 75%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.right-link {
  float: right;
  line-height: 17px;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

.filter_box {
  ul {
    padding-left: 10px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .date {
      // margin-left: 45px;
      width: 300px;

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      /deep/.el-input {
        width: 300px !important;

        .el-input__inner {
          width: 300px !important;
        }
      }
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #fff;
  }

  .el-select {
    width: 300px;
  }

  .el-input {
    width: 300px;
  }

  ._filter_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }

  ::v-deep .el-form-item > .el-form-item__label::before {
    content: "" !important;
    color: #000 !important;
  }

  ::v-deep .el-form-item__label::after {
    content: "*";
    color: #ffffff;
    margin-left: 4px;
  }

  ::v-deep .is-required .el-form-item__label::after {
    content: "*";
    color: #ff0000;
    margin-left: 4px;
  }
}
</style>