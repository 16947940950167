<template>
  <el-dialog :title="$t('BasicDept.title')" :visible="visible" @close="closed" width="1100px" class="_dialog_height2" append-to-body>
    <div class="settin-box">
      <div class="_right">
        <div class="content_left" v-loading="loading2">
          <div class="content" v-show="tabs == 0">
            <div>
              <el-input :placeholder="$t('CategoryDataList.filterText')" v-model="filterText">
              </el-input>
            </div>
            <el-tree class="treeTable" :data="dataTree" node-key="id" ref="tree"  :expand-on-click-node="false" :filter-node-method="filterNode"
              @node-click="handleNodeClick">
              >
              <span class="custom-tree-node" slot-scope="{ node,data }" @click="checkHuopin(data)">
                <el-tooltip :disabled="node.label.length<PDleng(node.label)" class="item" effect="dark" :content="node.label" placement="top-start">
                  <span style="font-size: 15px;" :style="TypeID == data.id ? 'color: #1d65ff;' : ''">
                    <i :class="data.children.length != 0 ? 'el-icon-folder-opened' : 'el-icon-folder'"></i> {{ node.label | ellipsis(PDleng(node.label)) }}
                  </span>
                </el-tooltip> 
              </span>
            </el-tree>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="content_right">
          <div class="table">
            <span class="title">{{ title }}</span>
            <div class="head_line">
              <el-input type="text" :placeholder="$t('AdminUser.NameOrNumber')" v-model="form.SearchName">
                <i class="el-icon-search" slot="suffix" @click="getAssetHuoPin()" style="line-height: 2;">
                </i>
              </el-input>
              <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
                  {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
            </div>
            <div style="position: relative;width: 100%;height: 388px">
              <el-table @select-all="selectAll" @select="select" style="position: absolute;" border ref="multipleTable" :data="tableData" v-loading="loading" height="388px" stripe
                :row-class-name="selectRowClassName" @selection-change="handleSelectionChange" @row-click="handleRowClick" @header-dragend="headerDragend">
                <el-table-column type="selection" width="55"> </el-table-column>

                <el-table-column :sortable="item.sort" v-for="(item, index) in TableList" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
                  <template slot-scope="scope">
                    <ImageView v-if="item.propName == 'HuoPinImage'" :url="scope.row.HuoPinImage"></ImageView>
                    <span v-else>
                      {{ scope.row[item.propName] }}
                    </span>
                  </template>
                </el-table-column>

                <div slot="empty" class="empty">
                  <img src="@/assets/img/empty.png" />
                  <span class="txt">{{ $t('BasicIndex.emptyData') }}
                    <font @click="addHuoPin(0)">{{ $t('CategoryDataList.add') }}</font>~
                  </span>
                </div>
              </el-table>
            </div>
            <div class="component_footer">
              <el-pagination v-if="tableData" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(form.PageIndex + 1)"
                :page-sizes="[20, 50, 100]" :page-size="form.PageSize" layout="total, prev, pager, next, jumper,sizes" :total="TotalCount">
              </el-pagination>
            </div>
            <el-divider></el-divider>
            <div class="_button">
              <el-button class="button_info" @click="closed">{{ $t('AssetAllotAdd.quit')}}</el-button>
              <el-button class="button_primary" @click="queyrHuoPin()"> {{ $t('AssetAllotAdd.query') }} </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DragTable v-if="TableOk" TableName="AssetHuoPinComponents" :show="TableOk" @message="Message_">
    </DragTable>
    <!-- 增加修改页面 -->
    <el-dialog :title="Title" :visible.sync="dialogFormVisible" center append-to-body>
      <el-form ref="form" :rules="rules" label-position="left" label-width="100px" input-width="100px" height="100px" :model="AssetType">
        <el-form-item :label="$t('CategoryDataList.TypeCode')" prop="TypeCode">
          <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetType.TypeCode"></el-input>
        </el-form-item>
        <el-form-item :label="$t('CategoryDataList.TypeCode')" prop="TypeName">
          <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetType.TypeName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('CategoryDataList.TypeCode')">
          <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="ParentTypeName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('CategoryDataList.TypeCode')">
          <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetType.ServiceLife"></el-input>
        </el-form-item>
        <el-form-item :label="$t('CategoryDataList.TypeCode')">
          <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetType.EXPWarning"></el-input>
        </el-form-item>
        <el-form-item :label="$t('CategoryDataList.TypeCode')">
          <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetType.EXPAlarm"></el-input>
        </el-form-item>
        <el-form-item :label="$t('CategoryDataList.TypeCode')" label-height="30px" prop="CategoryRemark">
          <el-input type="textarea" :rows="5" v-model="AssetType.TypeRemark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- 添加分类 -->
        <el-button type="primary" @click="append()"> {{ $t('CategoryDataList.query') }} </el-button>
      </div>
    </el-dialog>
    <!-- 增加修改货品页面 -->
    <el-dialog :title="Title" :rulesData="rulesData" :visible.sync="huoPinChecked" top="50px" append-to-body>
      <el-form ref="form" :rules="HuoPinrules" label-position="left" label-width="100px" input-width="100px" :model="AssetHuoPin">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('CategoryDataList.HuoPinName')" style="margin-right: 15px;" prop="HuoPinName">
              <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetHuoPin.HuoPinName" maxlength="32" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('CategoryDataList.HuoPinModels')">
              <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetHuoPin.HuoPinModels" maxlength="32" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('CategoryDataList.CalcUnit')" style="margin-right: 15px;">
              <el-input :placeholder="$t('CategoryDataList.tips1')" v-model="AssetHuoPin.CalcUnit" maxlength="32" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('CategoryDataList.DefaultPrice')">
              <el-input type="number" :placeholder="$t('CategoryDataList.tips1')" v-model="AssetHuoPin.DefaultPrice" maxlength="32" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('CategoryDataList.TypeID')" prop="TypeID">
              <TreeSelect v-model="AssetHuoPin.TypeID" @selectRoom="TypeClick" :data="TypeIDList" style="width:96%">
              </TreeSelect>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('CategoryDataList.HuoPinImage')">
              <input ref="imgfiles" :placeholder="$t('CategoryDataList.sel')" type="file" @change="selImg($event)" style="width: 2;height: 0;"
                accept="image/jpg,image/jpeg,image/png" />
              <div v-show="!AssetHuoPin.HuoPinImage" @click="upImg()" class="avatars">+</div>
              <img @click="upImg()" v-if="AssetHuoPin.HuoPinImage" :src="addUserImg || AssetHuoPin.HuoPinImage" class="avatar" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('CategoryDataList.HuoPinRemark')">
          <el-input type="textarea" rows="5" maxlength="512" show-word-limit v-model="AssetHuoPin.HuoPinRemark" style="width:95%"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="huoPinChecked = false" style="margin-right: 50px;">{{ $t('CategoryDataList.quit')
          }}</el-button><!--取消-->
        <!-- 添加货品 -->
        <el-button type="primary" @click="appendHuoPin()"> {{ $t('CategoryDataList.query') }} </el-button><!--确定-->
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  successTips,
  // 左
  GetAssetTypeTree,
  GetAssetType,
  AddAssetType,
  DeleteAssetType,
  LimitAssetType,
  LoadBasicUserColumn,
  SetBasicUserColumnWidth,
  // 右
  GetAssetHuoPinPage,
  GetAssetHuoPin,
  AddAssetHuoPin,
  UpdateAssetHuoPin,
  DeleteAssetHuoPin,
  // 文件
  upLoadFile,
  clearFile,
  updateTime,

} from "@/api/user";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  props: {
    type: {
      type: Boolean,
      default() {
        return false
      }
    },
    selectedTable: {
      type: Boolean,
      default: false
    },
    DataList: {
      type: Array,
      default() {
        return []
      }
    },
    seltype: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    visible() {
      return this.type;
    }
  },
  name: "SettingView",
  components: {},
  data() {
    return {
      ParentTypeName: '',
      drawer: false,
      TableOk: false,
      loading2:false,
      tabs: 0,
      menutab: 0,
      TypeID: '',
      ID: "",
      HuoPinID: "",
      loading: false,
      imageNum: '',
      level: "",
      filterText: "",
      icon: '',
      title: "",
      oldImg: '',
      updatetype: false,
      currentPage: 1,
      Title: "",
      checked: true,
      upload: false,
      dialogFormVisible: false,
      huoPinChecked: false,
      TableList: [],
      tableData: [],
      TypeIDList: [],
      HuoPinList: [],
      form: {
        ParentTypeID: '',
        TypeID: '',
        PageIndex: 0,
        PageSize: 20,
        StartTime: '',
        EndTime: '',
        SearchName: ''
      },
      AssetType: {
        CompanyID: '',
        CategoryCode: '',
        CategoryName: '',
        ParentCategoryID: '',
        ParentCategoryID1: '',
        ParentCategoryID2: '',
        ParentCategoryID3: '',
        ParentCategoryID4: '',
        ParentCategoryID5: '',
        ParentCategoryID6: '',
        ParentCategoryID7: '',
        ParentCategoryID8: '',
        ParentCategoryID9: '',
        ParentCategoryID10: '',
        CategoryLevel: '',
        CategoryStatus: '',
        CategoryRemark: ''
      },
      rules: {
        CategoryCode: [
          { required: true, message: this.$t('CategoryDataList.tips2'), trigger: 'blur' }
        ],
        TypeName: [
          { required: true, message: this.$t('CategoryDataList.tips3'), trigger: 'blur' }
        ],
        TypeRemark: [
          { required: true, message: this.$t('CategoryDataList.tips4'), trigger: 'blur' }
        ]
      },
      HuoPinrules: {
        TypeCode: [
          { required: true, message: this.$t('CategoryDataList.tips2'), trigger: 'blur' }
        ],
        TypeName: [
          { required: true, message: this.$t('CategoryDataList.tips3'), trigger: 'blur' }
        ],
        TypeRemark: [
          { required: true, message: this.$t('CategoryDataList.tips4'), trigger: 'blur' }
        ],
        TypeID: [
          { required: true, message: this.$t('CategoryDataList.tips5'), trigger: 'blur' }
        ],
        HuoPinName: [
          { required: true, message: this.$t('CategoryDataList.tips6'), trigger: 'blur' }
        ]
      },
      rulesData: {
        HuoPinName: [
          { required: true, message: this.$t('CategoryDataList.tips2'), trigger: 'blur' }
        ]

      },
      dataTree: [],
      SearchName: '',
      HuoPindata: [],
      AssetHuoPin: {
        ID: '',
        CompanyID: '',
        CategoryID: '',
        HuoPinCode: '',
        HuoPinName: '',
        HuoPinImage: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        DefaultPrice: '',
        CalcUnit: '',
        SafeStockLowerLimit: '',
        SafeStockUpperLimit: '',
        FactStock: '',
        RFIDStock: '',
        ElseStock: '',
        HuoPinStatus: '',
        HuoPinRemark: '',
        UpdateTime: '',
        CreateTime: '',
        SearchName: '',
        PageSize: '20',
        PageIndex: 0,
      },

      TotalCount: 0,
      addUserImg: '',
      categoryName: '',
      multipleSelection: [],
    };
  },
  filters: {
    ellipsis(value, len) {
      if (!value) return ''
      if (value.length > len) {
        return value.slice(0, len) + '...'
      }
      return value
    },
  },
  mounted() {
    this.getAssetHuoPin();
    this.getAssetType();
    this.show();
    this.getTypeIDList();
    this.getTableColumn();
  },
  methods: {
    select(selection) {
      if (selection.length > 1 && this.seltype == false) {
        let del_row = selection.shift()
        this.$refs.multipleTable.toggleRowSelection(del_row, false)
      }
    },
    selectAll(selection) {
      if (selection.length > 1 && this.seltype == false) {
        selection.length = 1
      }
    },
    PDleng(data) {
      if (/[\u4e00-\u9fa5]/g.test(data) == true) {
        return 8;
      } else {
        return 20;
      }
    },
    //分类点击事情
    TypeClick(e) {
      this.AssetHuoPin.TypeID = e.id;
      this.AssetHuoPin.TypeName = e.label;
    },
    //分类渲染
    getTypeIDList() {
      GetAssetTypeTree().then((res) => {
        if (res.Code == 0) {
          this.TypeIDList = res.Data;
        }
      });
    },
    show() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.DataList.length; j++) {
            if (this.tableData[i].HuoPinID == this.DataList[j]) {
              this.$refs.multipleTable.toggleRowSelection(this.tableData[i]);
            }
          }
        }
      }, 1000)
    },
    queyrHuoPin() {
      this.$emit('addHuopin', this.HuoPinList)
    },
    closed() {
      this.$emit('closed')
    },
    handleNodeClick(data) {
      this.AssetHuoPin.ID = data.id;
      this.TypeID = data.id;
      this.ParentTypeID = data.pid;
      this.level = data.level;
      this.form.ParentTypeID = data.id;
      this.form.TypeID = data.id;
      if (data.status == 1) {
        this.StatusTitle = this.$t('CategoryDataList.disabled');
      } else if (data.status == 2) {
        this.StatusTitle = this.$t('CategoryDataList.enabled');
      }
      this.getAssetHuoPin();
    },
    //照片区域
    upImg() {
      this.$refs.imgfiles.click();
    },
    selImg(e) {
      if (e.target.files.length == 0) {
        return
      }
      this.imageNum += 1;
      if (this.AssetHuoPin.HuoPinImage && !this.updatetype) {
        this.clearImg(this.AssetHuoPin.HuoPinImage);
      } else {
        this.oldImg = this.AssetHuoPin.HuoPinImage;
      }
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append('file', file);
      upLoadFile(formData).then(res => {
        if (res.Code == 0) {
          this.AssetHuoPin.HuoPinImage = res.Data;
        }
      })

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.addUserImg = e.target.result;
      }
    },
    // 关闭弹窗时删除已上传图片
    HideDialog() {
      if (this.AssetHuoPin.HuoPinImage) {
        this.clearImg(this.AssetHuoPin.HuoPinImage);
      }
      this.che = false;
    },

    // 删除图片
    clearImg(val) {
      if (this.Title == this.$t('CategoryDataList.update') && this.updatetype) {
        this.updatetype = false;
        if (this.imageNum > 0) {
          this.clearImg(this.AssetHuoPin.HuoPinImage);
          this.AssetHuoPin.HuoPinImage = '';
          this.imageNum = 0;
        }
        return
      }
      clearFile({ filename: val }).then(res => {
        if (res == 0) {
          this.AssetHuoPin.HuoPinImage = '';
        }
      })
    },
    //货品增删改查区域
    // 点击分类查询对应列表
    checkHuopin(data) {
      this.categoryName = data.label;
      this.AssetHuoPin.CategoryID = data.id;
      this.AssetHuoPin.TypeID = data.TypeID;
      this.form.CategoryID = data.id;
      this.AssetHuoPin.TypeID = data.TypeID;
      this.form.TypeID = data.TypeID;
    },
    //普通查询 
    getAssetHuoPin() {
      GetAssetHuoPinPage(this.form).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.tableData = res.Data;
          this.TotalCount = res.TotalCount;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].CreateTime = updateTime(this.tableData[i].CreateTime);
          }
        } else {
          this.tableData = [];
          this.TotalCount = 0;
        }
      });
    },
    clear() {
      this.AssetHuoPin.HuoPinBarCode = '';
      this.AssetHuoPin.BrandTrademark = '';
      this.AssetHuoPin.SafeStockUpperLimit = '';
      this.AssetHuoPin.SafeStockLowerLimit = '';
      this.AssetHuoPin.HuoPinStatus = '';
    },
    //新增货品页面
    addHuoPin() {
      for (let i = 0; i < this.AssetHuoPin.length; i++) {
        this.AssetHuoPin[i] = '';
      }
      if (this.TypeID != 0 || this.TypeID != "") {
        this.AssetHuoPin.TypeID = this.TypeID;
      }
      this.Title = this.$t('CategoryDataList.add');
      this.huoPinChecked = true;
    },
    //修改货品页面
    updateHuoPin() {
      this.huoPinChecked = true;
      this.Title = this.$t('CategoryDataList.update');
      GetAssetHuoPin({ HuoPinID: this.HuoPinID }).then((res) => {
        if (res.Code == 0) {
          this.AssetHuoPin = res.Data;
        }
      });
    },
    //删除货品
    deleteHuoPin() {
      DeleteAssetHuoPin({ HuoPinID: this.AssetHuoPin.ID }).then(res => {
        if (res.Code == 0) {
          this.SelectAssetHuoPin();
          successTips(res.Message);
        }
      })
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    //货品增删接口调用
    appendHuoPin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.Title == this.$t('CategoryDataList.add')) {
            AddAssetHuoPin(this.AssetHuoPin).then(
              (res) => {
                if (res.Code == 0) {
                  successTips(res.Message);
                  window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                  this.draftState[this.draftIndex] = false;
                  this.AssetHuoPin.HuoPinImage = '';
                  this.imageNum = 0;
                }
              }
            );
          } else if (this.Title == this.$t('CategoryDataList.update')) {
            UpdateAssetHuoPin(this.AssetHuoPin).then(
              (res) => {
                if (res.Code == 0) {
                  successTips(res.Message);
                  window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                  this.draftState[this.draftIndex] = false;
                  this.AssetHuoPin.HuoPinImage = '';
                  this.imageNum = 0;
                  this.updatetype = false;
                  this.oldImg = '';

                }
              }
            );
          }
          this.getAssetHuoPin();
          this.huoPinChecked = false; //弹窗页面是否开启
        }
      });

    },
    // 上传文件
    uploadHuoPin() {
      GetAssetTypeTree().then((res) => {
        if (res.Code == 0) {
          this.dataTree = res.Data;
        }
      });
    },

    //分类树形结构
    getAssetType() {
      this.loading2 = true;
      GetAssetTypeTree().then((res) => {
        if (res.Code == 0 && res.Data != null) {
          let obj = { pid: 0, id: 0, label: this.$t('CategoryDataList.alltype'), level: 1, status: 1, children: [] };
          res.Data.unshift(obj);
          this.dataTree = res.Data;
          this.loading2 = false;
        } else {
          this.dataTree = [];
          if (this.dataTree == null && this.dataTree.length == 0) {
            let obj = { pid: 0, id: 0, label: this.$t('CategoryDataList.alltype'), level: 1, status: 1, children: [] };
            this.dataTree.unshift(obj);
          }
          this.loading2 = false;
        }
      });
    },
    //新增分类接口确认
    append() {
      this.AssetType.ParentTypeID = this.TypeID;
      AddAssetType(this.AssetType).then(
        (res) => {
          if (res.Code == 0) {
            successTips(res.Message);
            this.dialogFormVisible = false; //弹窗页面是否开启
            this.getAssetType();
          }
        }
      );
    },
    //新增优化分类页面
    addAssetType() {
      for (let i in this.AssetType) {
        this.AssetType[i] = '';
      }
      this.dialogFormVisible = true;
    },
    //修改优化分类页面
    updateAssetType() {
      this.AssetType.ID = this.TypeID;
      GetAssetType({ TypeID: this.AssetType.ID }).then(
        (res) => {
          if (res.Code == 0) {
            this.AssetType = res.Data;
          }
        }
      );
      this.dialogFormVisible = true;
    },
    deleteAssetType() {
      this.AssetType.ID = this.TypeID;
      // 删除分类
      this.$confirm(this.$t('CategoryDataList.tips7'), this.$t('CategoryDataList.tipstitle'), {
        confirmButtonText: this.$t('CategoryDataList.query'),
        cancelButtonText: this.$t('CategoryDataList.quit'),
        type: 'warning'
      }).then(() => {
        DeleteAssetType({ TypeID: this.AssetType.ID }).then((res) => {
          if (res.Code == 0) {
            this.getAssetType();
            successTips(res.Message);

          }
        });
      }).catch(() => {

      });

    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    disabled(ID) {
      //禁用 
      alert(ID);
      GetAssetType({ TypeID: ID }).then((res) => {
        if (res.Code == 0) {
          this.AssetType = res.Data;
          let Status = parseInt(3 - res.Data.CategoryStatus);
          LimitAssetType({ ID: this.AssetType.ID, Status }).then((res) => {
            if (res.Code == 0) {
              successTips(res.Message);
            }
          });
        }
      });
    },
    filterTag(value, row) {
      return row.tag === value;
    },

    Search() { },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.HuoPinID == row.HuoPinID) {
          color = "warning-row";
        }
      });
      return color;
    },

    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'AssetHuoPinComponents' }).then(res => {
        if (res.Code == 0) {
          this.TableList = res.Data;
        }
      })
    },
    //刷新
    Message_() {
      this.getTableColumn();
      this.getAssetHuoPin();
      this.TableOk = false;
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'AssetHuoPinComponents', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },

    //选择整行
    handleRowClick(row) {
      if (!this.seltype) {
        this.$refs.multipleTable.clearSelection()
      }
      if (this.selectedTable == true) {
        this.$refs.multipleTable.clearSelection(); //单选
      }
      let index = this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.HuoPinID == row.HuoPinID
      });
      if (index == -1) {
        // 如果未存在，设置已选状态，并在list中添加这条数据
        this.$refs.multipleTable.toggleRowSelection(row, true); //设置复选框为选中状态
      } else {
        // 如果已存在，设置未选状态，并在list中删除这条数据
        this.$refs.multipleTable.toggleRowSelection(row, false); //设置复选框为未选状态
      }
    },
    handleSelectionChange(val) {
      this.HuoPinList = val;
      this.multipleSelection = val;
      //获取选中点击的数据 
      if (val.length == 1) {
        this.checked = false;
        this.HuoPinID = val[0].HuoPinID;
      } else {
        this.checked = true;
      }
      if (val.length == 0) return
      this.AssetHuoPin.HuoPinID = val[0].HuoPinID;
    },

    handleSizeChange(val) {
      this.form.PageSize = val;
      this.getAssetHuoPin();
    },
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);
      this.getAssetHuoPin();
    },
  },

};
</script>

<style lang="scss" scoped>
._button {
  display: flex;
  justify-content: end;
}
::v-deep .component_footer {
  align-items: end !important;
}
.el-pagination {
  justify-content: end !important;
}
::v-deep .component_footer {
  align-items: end !important;
}
.el-divider.el-divider--vertical {
  height: 583px !important;
}
.but_add {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog__body {
  height: 600px !important;
}
.el-divider.el-divider--vertical {
  height: 500px;
  margin-top: 25px;
  margin-left: 5px;
}

.el-dialog__title {
  font-size: 16px;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #c00;
  border-radius: 50%;
  /* 小圆点 */
  margin-right: 12px;
  /* 和文本之间的距离 */
  vertical-align: middle;
  /* 垂直居中 */
}

::v-deep .el-tree-node__content {
  margin-top: 10px;
}

::v-deep ._dialog_height {
  width: 1100px !important;
  height: 654px !important;
}

._footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

.treeTable {
  overflow: auto;
  max-height: 455px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon-plus {
  border: 1px dashed;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

._filter {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;
  }
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.avatars {
  width: 135px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 1;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: -42px;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  width: 135px;
  height: 128px;
  display: block;
  margin-top: -38px;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 600px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  margin-top: -20px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;
    margin-top: -20px;

    .content_left {
      width: 30%;
      height: 604px;
      padding: 16px 20px;
      font-size: 19px;
      margin-left: -18px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 0px 10px;

        span {
          font-size: 14px;
          max-width: 50px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        .cy li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          &:hover {
            background-color: #409eff;
            color: #fff;
            cursor: pointer;
          }

          span {
            margin-left: 10px;
          }
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .companyimg {
      line-height: 150px;
    }

    .content_right {
      height: calc(100vh - 700px);
      position: absolute;
      width: 70%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 50px;
        .el-input {
          width: 250px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}
</style>