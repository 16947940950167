<template>
  <!-- 货品明细页面 -->
  <el-drawer :title="$t('StorageCategory.HuoPinDetails')" :visible.sync="HuoPinChecked" direction="rtl" size="1000" @close="close">
    <div v-show="!OneFaStatic">
      <el-divider></el-divider>
      <div class="_info">
        <div class="content">
          <el-skeleton :rows="6" :loading="DataState == ''" animated>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageCategory.type') }}:</span>
                <font>{{ CategoryName }}</font>
              </p>
              <p>
                <span>{{ $t('StorageCategory.HuoPinCode') }}:</span>
                <font>{{ StorageHuoPin.HuoPinCode }}</font>
              </p>
            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageCategory.HuoPinName') }}:</span>
                <font>{{ StorageHuoPin.HuoPinName }}</font>
              </p>
              <p>
                <span>{{ $t('StorageCategory.HuoPinModels') }}:</span>
                <font>{{ StorageHuoPin.HuoPinModels }}</font>
              </p>
            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageCategory.HuoPinBarCode') }}:</span>
                <font>{{ StorageHuoPin.HuoPinBarCode }}</font>
              </p>
              <p>
                <span>{{ $t('StorageCategory.BrandTrademark') }}:</span>
                <font>{{ StorageHuoPin.BrandTrademark }}</font>
              </p>
            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageCategory.DefaultPrice') }}:</span>
                <font>{{ StorageHuoPin.DefaultPrice }}</font>
              </p>
              <p>
                <span>{{ $t('StorageCategory.SafeStockLowerLimit') }}:</span>
                <font>{{ StorageHuoPin.SafeStockLowerLimit }}</font>
              </p>
            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageCategory.SafeStockUpperLimit') }}:</span>
                <font>{{ StorageHuoPin.SafeStockUpperLimit }}</font>
              </p>
              <p>
                <span>{{ $t('StorageCategory.HuoPinRemark') }}:</span>
                <font>{{ StorageHuoPin.HuoPinRemark }}</font>
              </p>
            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageCategory.HuoPinImage') }}:</span>
                <font>
                  <img :src="StorageHuoPin.HuoPinImage" readonly class="avatar1" />
                </font>
              </p>
              <p>
                <span>{{ $t('StorageCategory.CalcUnit') }}:</span>
                <font>{{ StorageHuoPin.CalcUnit }}</font>
              </p>
            </div>
          </el-skeleton>
        </div> 
        <el-divider></el-divider>
        <div class="head_top1">
          <ul style="margin-left:-28px">
            <font class="fontStatus">{{ $t('StorageGoods.RFIDStatus') }}</font>
            <li v-for="(item, index) in options" :key="index" @click="selStatus(item)" :class="{ 'hoverBg': menutab == index }">
              {{ item.label }}
            </li>
            <el-divider direction="vertical"></el-divider>
            <li> <el-input type="text" style="width:220px;margin-left:20px" :placeholder="$t('StorageGoods.Name_Number')" class="SearchName" v-model="form.SearchName"
                @change="getData()" suffix-icon="el-icon-search"></el-input>
            </li>
            <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;float:right;cursor: pointer;line-height: 30px;">
                {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
          </ul>

        </div>
        <div style="position: relative;width: 100%;height: calc(100vh - 420px);">
          <el-table @selection-change="handleSelectionChange" @row-click="handleRowClick" style="position: absolute;" border ref="multipleTable" :data="GoodsNotes"
            height="calc(100vh - 400px)" stripe @header-dragend="headerDragend">
            <el-table-column type="selection" width="55"> </el-table-column>
            <!-- 表头使用GoodsTable数据 -->
            <el-table-column :sortable="item.sort" v-for="(item, index) in GoodsTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
              <template slot-scope="scope">
                <span>
                  {{ scope.row[item.propName] }}
                </span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" :label="$t('StorageGoods.Operate')" width="150px" align="center">
              <template slot-scope="scope">
                <div class="_operate">
                  <el-link type="primary" v-if="form.RFIDStatus == 'NO'" @click="Onfaka(scope.row)">{{
                    $t('StorageGoods.Faka') }}</el-link>
                  <el-link type="danger" v-else @click.stop="unBinDan(scope.row)">{{ $t('StorageGoods.UnBind') }}</el-link>
                </div>
              </template>
            </el-table-column>
            <div slot="empty" class="empty">
              <img src="@/assets/img/empty.png" />
              <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
            </div>
          </el-table>
        </div>
        <div class="table_footer">
          <div class="footer_left">
            <el-button class="button_info" @click.native="viewtype = TableName, getColumn(), uptype = 'out'">{{
                $t('StorageGoods.importOutGoods') }}</el-button>
            <el-button class="button_primary" v-if="form.RFIDStatus == 'NO'" :disabled="FaTable.length==0" @click="FaKa()">{{$t('StorageGoods.PiLiangFaKa')}}</el-button>
            <el-button class="button_waring" v-else @click="showTips()" :disabled="FaTable.length==0">{{$t('StorageGoods.PiLiangUnbind')}}</el-button>
          </div>
          <div class="footer_right">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(form.PageIndex + 1)" :page-sizes="[20, 50, 100]"
              :page-size="form.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
            </el-pagination>
          </div>
        </div>
      </div>

    </div>
    <DragTable v-if="TableOk" :TableName="TableName" :show="TableOk" @message="Message_">
    </DragTable>
    <!-- 单个发卡 -->
    <GoodsDanFa v-if="OneFaStatic" :show="OneFaStatic" :FaData="FaKaStorageHuoPin" @close="OneFaStatic = false" :checked="goodsChecked" :Title="Title" @Message="message">
    </GoodsDanFa>
    <!-- 批量发卡 -->
    <GoodsBatchFaKa v-if="FaStatic" :show="FaStatic" @close="FaStatic = false" :Title="Title" @Message="message" :checked="goodsChecked" :GoodsData="GoodsListID">
    </GoodsBatchFaKa>
    <ExportView v-if="upload" :state="upload" :uptype="uptype" :exportColumn="exportColumn" :error="errString" :errUrl="errUrl" :importUrl="`TemplateName=${viewtype}`"
      @close="upload = false" @clean="errString = '', errUrl = ''" @submit="ExportInput"></ExportView>
    <MessageTip :show="shows" :text="messcontent" @cancel="shows = false" @confirm="unBindStorageFaka"></MessageTip>
  </el-drawer>
</template>
  
<script>
import {
  GetStorageHuoPin,
  GetStorageCategory,
  UnBindStorageGoodsEPCList,
  GetExportDownUrl,
  GetExportColumn,
  SetBasicUserColumnWidth,
  LoadBasicUserColumn,
  successTips,
  msgTips,
  GetStorageGoodsPage
} from "@/api/user";
export default {
  props: {
    show: {
      type: Boolean,
    },
    HuoPinID: {
      type: Number,
      default: 0
    },
    HuoPinCode: {
      type: String,
      default: ""
    }

  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  components: {},
  data() {
    return {
      options: [
        {
          value: 'NO',
          label: this.$t('StorageGoods.NoFaKa'),
          name: 1,
        }, {
          value: 'YES',
          label: this.$t('StorageGoods.YESFaKa'),
          name: 2,
        }],
      TableName: 'GoodsStockNOBind',
      // 表头数据
      GoodsTable: [],
      errString: "",
      exportColumn: [],
      GoodsListID: [],
      viewtype: "",
      shows: false,
      uptype: '',
      upload: false,
      GoodsNotes: [],
      FaTable: [],
      OneGoodsListID: [],
      menutab: 0,
      TotalCount: 0,
      GoodsIDText: "",
      errUrl: '',
      messcontent: {},
      Title: "",
      TableOk: false,
      OneFaStatic: false,
      FaStatic: false,
      dialogFormVisible: false,
      goodsChecked: false,
      HuoPinChecked: false,
      DataState:'',
      CategoryName: '',
      form: {
        PageIndex: 0,
        PageSize: 20,
        OnlyShowStock: 1,
        SearchName: '',
        HuoPinCode: '',
        HuoPinName: '',
        RFIDStatus: "NO",
        GoodsRFIDEPC: '',
        BatchNumber: '',
        CalcUnit: '',
        CategoryID: '',
        CategoryName: '',
        WarehouseID: '',
        WarehouseName: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        HuoPinID: '',
        SortType: '',
        SortTitle: ''
      },
      FaKaStorageHuoPin: {
        HuoPinID: '',
        CompanyID: '',
        CategoryID: '',
        HuoPinCode: '',
        HuoPinName: '',
        HuoPinImage: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        DefaultPrice: '',
        CalcUnit: '',
        SafeStockLowerLimit: '',
        SafeStockUpperLimit: '',
        FactStock: '',
        RFIDStock: '',
        ElseStock: '',
        HuoPinStatus: '',
        HuoPinRemark: '',
        UpdateTime: '',
        CreateTime: '',
        SearchName: '',
        PageSize: '20',
        PageIndex: 0,
      },
      StorageHuoPin: {
        ID: '',
        ParentCategoryID: '',
        CompanyID: '',
        CategoryID: '',
        HuoPinID: 0,
        HuoPinCode: '',
        HuoPinName: '',
        HuoPinImage: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        DefaultPrice: '',
        CalcUnit: '',
        SafeStockLowerLimit: '',
        SafeStockUpperLimit: '',
        FactStock: '',
        RFIDStock: '',
        ElseStock: '',
        HuoPinStatus: '',
        HuoPinRemark: '',
        UpdateTime: '',
        CreateTime: '',
        SearchName: '',
        PageSize: '20',
        PageIndex: 0,
      },

    };
  },
  mounted() {
    this.HuoPinChecked = this.show;
    this.form.HuoPinID = this.HuoPinID;
    this.getData();
    this.getTableColumn();
    //获取货品明细
    GetStorageHuoPin({ HuoPinID: this.HuoPinID }).then((res) => {
      if (res.Data) {
        this.StorageHuoPin = res.Data;
        //获取种类名称
        GetStorageCategory({ CategoryID: this.StorageHuoPin.CategoryID }).then((res) => {
          if (res.Data) {
            this.CategoryName = res.Data.CategoryName;
          }
        });
      }
    });
  },
  methods: {
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: this.TableName }).then(res => {
        if (res.Code == 0) {
          this.GoodsTable = res.Data;
        }
      })
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: this.TableName, ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    //刷新
    Message_(e) {
      if (e == 0) {
        this.getData();
        this.getTableColumn();
      }
      this.TableOk = false;
    },
    //发卡
    FaKa() {
      this.GoodsListID = [];
      let data = [];
      for (let i = 0; i < this.FaTable.length; i++) {
        if (this.FaTable[i].FactStock != 0) {
          let val = JSON.parse(JSON.stringify(this.FaTable[i]));
          val.FaKaCount = val.FactStock;
          if (this.FaTable[i].GoodsRFIDEPC == this.$t('StorageGoods.NoFaKa')) {
            val.GoodsRFIDEPC = "";
          }
          data.push({ index: i, ...val });
        }
        this.GoodsListID = data;
      }
      if (this.GoodsListID.length != 0) {
        this.FaStatic = true;
      } else {
        msgTips(this.$t('StorageGoods.msgTips'));
        return;
      }

    },
    //导入组件事件
    ExportInput(value, state) {
      if (state == 'out') {
        let { ExportType } = value;
        let Head = JSON.stringify(value.Head)
        let SearchJson = JSON.stringify(this.form);
        GetExportDownUrl({ Head, ExportType, SearchJson, ExportName: this.viewtype }).then(res => {
          if (res.Code == 0) {
            let a = document.createElement('a');
            a.href = res.Data;
            a.click();
            document.removeChild(a)
          }
        })
      }
    },
    //解绑单个物资
    unBinDan(e) {
      console.log(e);
      let goodsID = [];
      goodsID.push(e.GoodsID);
      this.GoodsIDText = JSON.stringify(goodsID);
      UnBindStorageGoodsEPCList(this.GoodsIDText).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.getData();
          this.shows = false;
          return;
        }
      });
    },
    //解绑物资 
    unBindStorageFaka() {
      UnBindStorageGoodsEPCList(this.GoodsIDText).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.getData();
          this.shows = false;
          return;
        }
      });

    },
    //导出
    getColumn() {
      GetExportColumn({ ExportName: this.viewtype }).then(res => {
        if (res.Code == 0) {
          this.exportColumn = res.Data;
          this.upload = true;
        }
      })
    },
    showTips() {
      this.messcontent = {
        title: this.$t('StorageGoods.Tips'),
        content: this.$t('StorageGoods.UnbindMsgTips'),
        confirmButtonText: this.$t('StorageGoods.confirm'),
        cancelButtonText: this.$t('StorageGoods.cancel'),
        type: 'warning'
      }
      this.shows = true;
    },
    //每页数量
    handleSizeChange(val) {
      this.form.PageSize = val;
      this.getData();
    },
    //第几页
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);
      this.getData();
    },
    //选择整行
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //获取选中点击的数据 
    handleSelectionChange(val) {
      this.FaTable = [];
      if (val.length > 0) {
        this.FaTable = val;
      }
      this.GoodsListID = val;
      let goodsID = [];
      for (let i = 0; i < val.length; i++) {
        goodsID.push(val[i].GoodsID);
      }
      this.GoodsIDText = JSON.stringify(goodsID);
    },
    //打开单个发卡页面
    Onfaka(e) {
      this.OneGoodsListID = [];
      let obj = e;
      this.FaKaStorageHuoPin = obj;
      this.OneFaStatic = true;
    },
    //获取数据
    getData() { 
      GetStorageGoodsPage(this.form).then((res) => {
        if (res.Data) {
          this.GoodsNotes = res.Data;
          this.TotalCount = res.TotalCount;
          this.DataState = '1';
        }
        else {
          this.GoodsNotes = [];
          this.TotalCount = 0;
          this.DataState = '';
        }
      });
    },
    //刷新
    message(e) {
      if (e == 0) {
        this.dialogFormVisible = false;
        this.getData();
      }
    },
    //领用状态
    selStatus(e) {
      this.menutab = e.name - 1;
      this.form.RFIDStatus = e.value;
      this.GoodsListID = [];
      if (e.name == 1) {
        this.TableName = 'GoodsStockNOBind';
      } else {
        this.TableName = 'GoodsStockBindRFID';
      }
      this.getTableColumn();
      this.getData();
    },
    //清空表格数据
    close() {
      this.$emit("close");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.el-divider--vertical {
  height: 2em;
  margin-left: 15px;
}
::v-deep .el-drawer__header {
  margin-bottom: 20px !important;
  padding: 10px 20px 0 !important;
}
.table_footer {
  margin-top: 15px;
}
.el-divider--horizontal {
  margin: 10px 0px;
}
.fontStatus {
  margin-right: 12px;
  font-size: 14px;
}

.head_top1 ul li {
  display: inline-block;
  margin-left: 12px;
  margin-top: -15px;
  margin-bottom: -15px;
  border-radius: 100px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  white-space: pre-wrap;
}

.hoverBg {
  background-position: 0 0%;
  color: #1c68ff;
  background: #f2f3f5;
}

._info {
  width: 100%;
  min-width: 600px;
  padding: 20px;

  .title {
    color: #4e5969;
    font-size: 14px;
    font-weight: bold;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: -22px;
    .content_item {
      height: 33px;
      flex: 1;
      display: flex;

      p {
        margin: 0;
        flex: 1;
        display: flex;
        align-items: center;
        height: 30px;
      }

      span {
        display: inline-block;
        min-width: 80px;
        // text-align: right;
        color: #86909c;
        font-size: 14px;
      }
    }
  }

  img {
    width: 30px;
    height: 30px;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 160px;
    height: 160px;
  }

  span {
    line-height: 30px;
  }

  font {
    color: #5c90ff;
    cursor: pointer;
  }
}

/deep/.el-drawer {
  width: 1000px;
}

._footer {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>