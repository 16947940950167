import Vue from "vue";
import VueRouter from "vue-router";
import { CheckMenuAuthority } from "@/api/user";
Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routes = [
  {
    path: "/login",
    meta: {
      info: "登录",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/DataCenter",
    meta: {
      info: "思维导图",
    },
    component: () => import("@/views/DataCenter.vue"),
  },
  {
    path: "/assetoverview",
    meta: {
      info: "资产数据大屏",
    },
    component: () => import("@/views/AssetOverview.vue"),
  },
  {
    path: "/storageoverview",
    meta: {
      info: "库存数据大屏",
    },
    component: () => import("@/views/StorageOverview.vue"),
  },
  {
    path: "/luckysheet",
    meta: {
      info: "自由打印模板",
    },
    component: () => import("@/views/PrintView.vue"),
  },
  {
    path: "/reception",
    meta: {
      info: "前台",
    },
    component: () => import("@/views/reception.vue"),
    children: [
      {
        path: "/reception/myasset",
        meta: {
          info: "我的资产",
        },
        component: () => import("@/views/reception/MyAssetList.vue"),
      },
      {
        path: "/reception/requisitionandapplication",
        meta: {
          info: "领用退库",
        },
        component: () =>
          import("@/views/reception/RequisitionAndApplication.vue"),
      },
      {
        path: "/reception/borrowandreturn",
        meta: {
          info: "借用归还",
        },
        component: () => import("@/views/reception/BorrowAndReturn.vue"),
      },
      {
        path: "/reception/handover",
        meta: {
          info: "资产交接",
        },
        component: () => import("@/views/reception/Handover.vue"),
      },
      {
        path: "/reception/scrap",
        meta: {
          info: "报废申请",
        },
        component: () => import("@/views/reception/Scrap.vue"),
      },
      {
        path: "/reception/repair",
        meta: {
          info: "资产报修",
        },
        component: () => import("@/views/reception/Repair.vue"),
      },
      {
        path: "/reception/inventory",
        meta: {
          info: "员工盘点",
        },
        component: () => import("@/views/reception/Inventory.vue"),
      },
    ],
  },
  {
    path: "/register",
    meta: {
      info: "注册",
    },
    component: () => import("@/views/register/Register.vue"),
  },
  {
    path: "/reset",
    meta: {
      info: "重置密码",
    },
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/UserInformation",
    meta: {
      info: "完善用户信息",
    },
    component: () => import("@/views/register/UserInformation.vue"),
  },
  {
    path: "/EnterpriseInformation",
    meta: {
      info: "完善企业信息",
    },
    component: () => import("@/views/register/EnterpriseInformation.vue"),
  },
  {
    path: "/nopermission",
    meta: {
      info: "暂无访问权限",
    },
    component: () => import("@/views/NoPermission.vue"),
  },
  {
    path: "/indexpower",
    component: () => import("@/views/manage/ManageIndex.vue"),
    children: [
      {
        path: "/power",
        meta: {
          info: "系统权限",
        },
        component: () => import("@/views/manage/SystemPower.vue"),
      },
      {
        path: "/enterprise",
        meta: {
          info: "企业列表",
        },
        component: () => import("@/views/manage/SystemEnterprise.vue"),
      },
      {
        path: "/ManyCompanyAnalysis",
        meta: {
          info: "企业分析",
        },
        component: () => import("@/views/manage/ManyCompanyAnalysis.vue"),
      },
      {
        path: "/managecolumm",
        meta: {
          info: "表单管理",
        },
        component: () => import("@/views/manage/ManageColumm.vue"),
      },
      {
        path: "/managepackage",
        meta: {
          info: "套餐管理",
        },
        component: () => import("@/views/manage/PackageManage.vue"),
      },
      {
        path: "/upgraderecord",
        meta: {
          info: "升级记录",
        },
        component: () => import("@/views/manage/UpgradeRecord.vue"),
      },
      {
        path: "/manageorder",
        meta: {
          info: "订单管理",
        },
        component: () => import("@/views/manage/ManageOrder.vue"),
      },
      {
        path: "/manageprint",
        meta: {
          info: "打印模板管理",
        },
        component: () => import("@/views/manage/ManagePrint.vue"),
      },
    ],
  },
  {
    path: "/editsheet",
    meta: {
      info: "模版设计",
    },
    component: () => import("@/views/manage/components/EditSheet.vue"),
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/",
    component: () => import("@/views/Index.vue"),
    children: [
      {
        path: "/home",
        meta: {
          info: "首页",
        },
        component: () => import("@/views/Home.vue"),
      },
      // 库存管理
      {
        path: "/storage/homepage",
        meta: {
          info: "库存首页",
        },
        component: () => import("@/views/storage/HomePage.vue"),
      },
      {
        path: "/storage/goods",
        meta: {
          info: "库存清单",
        },
        component: () => import("@/views/storage/Goods.vue"),
      },
      {
        path: "/storage/faka",
        meta: {
          info: "发卡",
        },
        component: () => import("@/views/storage/FaKa.vue"),
      },
      {
        path: "/storage/inkudan",
        meta: {
          info: "物资入库",
        },
        component: () => import("@/views/storage/InKu.vue"),
      },
      {
        path: "/storage/apply",
        meta: {
          info: "领用申请",
        },
        component: () => import("@/views/storage/Apply.vue"),
      },
      {
        path: "/storage/outkuandrevert",
        meta: {
          info: "出库&退库",
        },
        component: () => import("@/views/storage/OutKu.vue"),
      },
      {
        path: "/storage/borrowandreturn",
        meta: {
          info: "领用归还",
        },
        component: () => import("@/views/storage/BrrowAndReturn.vue"),
      },
      {
        path: "/storage/move",
        meta: {
          info: "物资移库",
        },
        component: () => import("@/views/storage/Move.vue"),
      },
      {
        path: "/storage/allot",
        meta: {
          info: "物资调拨",
        },
        component: () => import("@/views/storage/Allot.vue"),
      },
      {
        path: "/storage/pandian",
        meta: {
          info: "库存盘点",
        },
        component: () => import("@/views/storage/PanDian.vue"),
      },
      {
        path: "/storage/change",
        meta: {
          info: "库存调整",
        },
        component: () => import("@/views/storage/Change.vue"),
      },
      {
        path: "/storage/reporta",
        meta: {
          info: "库存实时统计",
        },
        component: () => import("@/views/storage/report/ReportA.vue"),
      },
      {
        path: "/storage/reportb",
        meta: {
          info: "物资分类统计",
        },
        component: () => import("@/views/storage/report/ReportB.vue"),
      },
      {
        path: "/storage/reportc",
        meta: {
          info: "物资申领统计",
        },
        component: () => import("@/views/storage/report/ReportC.vue"),
      },
      {
        path: "/storage/reportd",
        meta: {
          info: "物资入库统计",
        },
        component: () => import("@/views/storage/report/ReportD.vue"),
      },
      {
        path: "/storage/reporte",
        meta: {
          info: "物资出库统计",
        },
        component: () => import("@/views/storage/report/ReportE.vue"),
      },
      {
        path: "/storage/reportf",
        meta: {
          info: "部门领用汇总",
        },
        component: () => import("@/views/storage/report/ReportF.vue"),
      },
      {
        path: "/storage/reportg",
        meta: {
          info: "个人领用汇总",
        },
        component: () => import("@/views/storage/report/ReportG.vue"),
      },
      {
        path: "/storage/reporth",
        meta: {
          info: "部门出库汇总",
        },
        component: () => import("@/views/storage/report/ReportH.vue"),
      },
      {
        path: "/storage/reporti",
        meta: {
          info: "个人出库汇总",
        },
        component: () => import("@/views/storage/report/ReportI.vue"),
      },
      {
        path: "/storage/reportj",
        meta: {
          info: "库房出入对比",
        },
        component: () => import("@/views/storage/report/ReportJ.vue"),
      },
      {
        path: "/storage/warehouse",
        meta: {
          info: "库房管理",
        },
        component: () => import("@/views/storage/setting/WarehouseManage.vue"),
      },
      {
        path: "/storage/category",
        meta: {
          info: "种类管理",
        },
        component: () => import("@/views/storage/setting/CategoryManage.vue"),
      },
      {
        path: "/storage/labeltemplate",
        meta: {
          info: "标签模板",
        },
        component: () => import("@/views/storage/setting/Label.vue"),
      },
      {
        path: "/storage/setting",
        meta: {
          info: "库存核算设置",
        },
        component: () => import("@/views/storage/setting/SettingManage.vue"),
      },
      {
        path: "/storage/huopin",
        meta: {
          info: "货品管理",
        },
        component: () => import("@/views/storage/setting/HuoPin.vue"),
      },
      {
        path: "/storage/shelf",
        meta: {
          info: "货架管理",
        },
        component: () => import("@/views/storage/setting/Shelf.vue"),
      },

      // 财务管理
      {
        path: "/financial/setting",
        meta: {
          info: "财务设置",
        },
        component: () => import("@/views/financial/Setting.vue"),
      },
      {
        path: "/financial/rePort1",
        meta: {
          info: "库报表1",
        },
        component: () => import("@/views/financial/report/ReportOne.vue"),
      },
      {
        path: "/financial/rePort2",
        meta: {
          info: "库报表2",
        },
        component: () => import("@/views/financial/report/ReportTwo.vue"),
      },
      {
        path: "/financial/rePort3",
        meta: {
          info: "库报表3",
        },
        component: () => import("@/views/financial/report/ReportThree.vue"),
      },
      {
        path: "/financial/rePort4",
        meta: {
          info: "库报表4",
        },
        component: () => import("@/views/financial/report/ReportFour.vue"),
      },
      {
        path: "/financial/deal",
        meta: {
          info: "财务处置",
        },
        component: () => import("@/views/financial/Deal.vue"),
      },
      {
        path: "/financial/depreciate",
        meta: {
          info: "资产折旧",
        },
        component: () => import("@/views/financial/Depreciate.vue"),
      },
      {
        path: "/financial/change",
        meta: {
          info: "财务变更",
        },
        component: () => import("@/views/financial/Change.vue"),
      },
      {
        path: "/financial/card",
        meta: {
          info: "资产卡片",
        },
        component: () => import("@/views/financial/Card.vue"),
      },
      {
        path: "/financial/homepage",
        meta: {
          info: "财务首页",
        },
        component: () => import("@/views/financial/HomePage.vue"),
      },

      // 资产管理
      {
        path: "/asset/setting",
        meta: {
          info: "资产核算设置",
        },
        component: () => import("@/views/asset/setting/SettingManage.vue"),
      },
      {
        path: "/asset/type",
        meta: {
          info: "分类管理",
        },
        component: () => import("@/views/asset/setting/Type.vue"),
      },
      {
        path: "/asset/area",
        meta: {
          info: "区域管理",
        },
        component: () => import("@/views/asset/setting/Area.vue"),
      },
      {
        path: "/asset/huopin",
        meta: {
          info: "货品管理",
        },
        component: () => import("@/views/asset/setting/HuoPin.vue"),
      },
      {
        path: "/asset/labletemplate",
        meta: {
          info: "标签模板",
        },
        component: () => import("@/views/asset/setting/Label.vue"),
      },
      {
        path: "/asset/reporta",
        meta: {
          info: "实时资产库",
        },
        component: () => import("@/views/asset/report/ReportA.vue"),
      },
      {
        path: "/asset/reportb",
        meta: {
          info: "分类汇总",
        },
        component: () => import("@/views/asset/report/ReportB.vue"),
      },
      {
        path: "/asset/reportc",
        meta: {
          info: "标准品汇总",
        },
        component: () => import("@/views/asset/report/ReportC.vue"),
      },
      {
        path: "/asset/reportd",
        meta: {
          info: "公司资产统计",
        },
        component: () => import("@/views/asset/report/ReportD.vue"),
      },
      {
        path: "/asset/reporte",
        meta: {
          info: "区域资产统计",
        },
        component: () => import("@/views/asset/report/ReportE.vue"),
      },
      {
        path: "/asset/reportf",
        meta: {
          info: "公司使用统计",
        },
        component: () => import("@/views/asset/report/ReportF.vue"),
      },
      {
        path: "/asset/reportg",
        meta: {
          info: "部门使用统计",
        },
        component: () => import("@/views/asset/report/ReportG.vue"),
      },
      {
        path: "/asset/reporth",
        meta: {
          info: "个人使用统计",
        },
        component: () => import("@/views/asset/report/ReportH.vue"),
      },
      {
        path: "/asset/reporti",
        meta: {
          info: "资产领退统计",
        },
        component: () => import("@/views/asset/report/ReportI.vue"),
      },
      {
        path: "/asset/reportj",
        meta: {
          info: "资产借还统计",
        },
        component: () => import("@/views/asset/report/ReportJ.vue"),
      },
      {
        path: "/asset/reportk",
        meta: {
          info: "即将过期资产",
        },
        component: () => import("@/views/asset/report/ReportK.vue"),
      },
      {
        path: "/asset/reportl",
        meta: {
          info: "即将归还超时资产",
        },
        component: () => import("@/views/asset/report/ReportL.vue"),
      },
      {
        path: "/asset/assptpuscl",
        meta: {
          info: "分类使用统计",
        },
        component: () => import("@/views/asset/report/ReporSix.vue"),
      },
      {
        path: "/asset/asspususcl",
        meta: {
          info: "个人使用统计",
        },
        component: () => import("@/views/asset/report/ReportEight.vue"),
      },

      {
        path: "/asset/clearscrap",
        meta: {
          info: "清理报废",
        },
        component: () => import("@/views/asset/assetmanage/ClearScrap.vue"),
      },
      {
        path: "/asset/repairgoods",
        meta: {
          info: "待维修资产",
        },
        component: () => import("@/views/asset/maintenance/RepairGoods.vue"),
      },
      {
        path: "/asset/repairplan",
        meta: {
          info: "资产报修",
        },
        component: () => import("@/views/asset/maintenance/RepairPlan.vue"),
      },
      {
        path: "/asset/maintenancegoods",
        meta: {
          info: "待保养资产",
        },
        component: () =>
          import("@/views/asset/maintenance/MaintenanceGoods.vue"),
      },
      {
        path: "/asset/maintenanceplan",
        meta: {
          info: "保养计划",
        },
        component: () =>
          import("@/views/asset/maintenance/MaintenancePlan.vue"),
      },
      {
        path: "/asset/repairwork",
        meta: {
          info: "资产维修",
        },
        component: () => import("@/views/asset/maintenance/RepairWork.vue"),
      },
      {
        path: "/asset/maintenancework",
        meta: {
          info: "资产保养",
        },
        component: () =>
          import("@/views/asset/maintenance/MaintenanceWork.vue"),
      },
      {
        path: "/asset/financialchange",
        meta: {
          info: "财务变更",
        },
        component: () => import("@/views/asset/Financial.vue"),
      },
      {
        path: "/asset/childchange",
        meta: {
          info: "附属变更",
        },
        component: () => import("@/views/asset/assetmanage/ChildChange.vue"),
      },
      {
        path: "/asset/goodschange",
        meta: {
          info: "资产变更",
        },
        component: () => import("@/views/asset/assetmanage/GoodsChange.vue"),
      },
      // {
      //     path: '/asset/childchange',
      //     meta: {
      //         info: '资产组合',
      //     },
      //     component: () => import('@/views/asset/assetmanage/ChildChange.vue')
      // },
      {
        path: "/asset/allot",
        meta: {
          info: "资产调拨",
        },
        component: () => import("@/views/asset/assetmanage/Allot.vue"),
      },
      {
        path: "/asset/handover",
        meta: {
          info: "资产交接",
        },
        component: () => import("@/views/asset/assetmanage/HandOver.vue"),
      },
      {
        path: "/asset/disposal",
        meta: {
          info: "资产处置",
        },
        component: () => import("@/views/asset/assetmanage/DisPosal.vue"),
      },
      {
        path: "/asset/pandian",
        meta: {
          info: "资产盘点",
        },
        component: () => import("@/views/asset/PanDian.vue"),
      },
      {
        path: "/asset/borrowandreturn",
        meta: {
          info: "借用&归还",
        },
        component: () => import("@/views/asset/BorrowAndReturn.vue"),
      },
      // {
      //     path: '/asset/return',
      //     meta: {
      //         info: '资产归还',
      //     },
      //     component: () => import('@/views/asset/AssetReturn.vue')
      // },
      {
        path: "/asset/requisitionandrevert",
        meta: {
          info: "领用&退库",
        },
        component: () => import("@/views/asset/RequisitionAndRevert.vue"),
      },
      {
        path: "/asset/inkudan",
        meta: {
          info: "入库单",
        },
        component: () => import("@/views/asset/Inkudan.vue"),
      },
      {
        path: "/asset/inkuhistory",
        meta: {
          info: "入库记录",
        },
        component: () => import("@/views/asset/InkuHistory.vue"),
      },
      {
        path: "/asset/faka",
        meta: {
          info: "发卡",
        },
        component: () => import("@/views/asset/FaKa.vue"),
      },
      {
        path: "/asset/goods",
        meta: {
          info: "资产列表",
        },
        component: () => import("@/views/asset/AssetList.vue"),
      },
      {
        path: "/asset/homepage",
        meta: {
          info: "资产首页",
        },
        component: () => import("@/views/asset/HomePage.vue"),
      },

      //系统管理
      {
        path: "/basic/dept",
        meta: {
          info: "组织架构",
        },
        component: () => import("@/views/basic/Dept.vue"),
      },
      {
        path: "/basic/manage",
        meta: {
          info: "管理员",
        },
        component: () => import("@/views/basic/Manage.vue"),
      },
      {
        path: "/basic/menu",
        meta: {
          info: "权限管理",
        },
        component: () => import("@/views/basic/MenuPower.vue"),
      },
      {
        path: "/basic/company",
        meta: {
          info: "企业信息",
        },
        component: () => import("@/views/basic/Company.vue"),
      },
      // {
      //     path: '/basic/user',
      // meta:{
      //     info: '用户信息',
      // },
      //     component: () => import('@/views/basic/User.vue')
      // },
      {
        path: "/basic/rfidlable",
        meta: {
          info: "标签管理",
        },
        component: () => import("@/views/basic/rfid/Lable.vue"),
      },
      {
        path: "/basic/rfiddevice",
        meta: {
          info: "设备管理",
        },
        component: () => import("@/views/basic/rfid/Device.vue"),
      },
      // {
      //     path: '/basic/setting/user',
      // meta:{
      //     info: '员工门户',
      // },
      //     component: () => import('@/views/basic/setting/User.vue')
      // },
      // {
      //     path: '/basic/setting/general',
      // meta:{
      //     info: '通用设置',
      // },
      //     component: () => import('@/views/basic/setting/General.vue')
      // },

      {
        path: "/basic/loglogin",
        meta: {
          info: "登录日志",
        },
        component: () => import("@/views/basic/log/Login.vue"),
      },
      {
        path: "/basic/logplatform",
        meta: {
          info: "平台日志",
        },
        component: () => import("@/views/basic/log/Platform.vue"),
      },
      {
        path: "/basic/logapplication",
        meta: {
          info: "应用日志",
        },
        component: () => import("@/views/basic/log/Application.vue"),
      },
      {
        path: "/basic/setting",
        meta: {
          info: "系统设置",
        },
        component: () => import("@/views/basic/Setting.vue"),
      },
      {
        path: "/basic/approve",
        meta: {
          info: "审批流",
        },
        component: () => import("@/views/basic/Approve.vue"),
      },
      {
        path: "/basic/rfidtool",
        meta: {
          info: "RFID工具",
        },
        component: () => import("@/views/basic/rfid/RfidTool.vue"),
      },
      {
        path: "/basic/publictemplate",
        meta: {
          info: "通用模板",
        },
        component: () =>
          import("@/views/basic/publictemplate/PublicTemplate.vue"),
      },
      {
        path: "/basic/usertemplate",
        meta: {
          info: "员工模板",
        },
        component: () =>
          import("@/views/basic/publictemplate/UserTemplate.vue"),
      },
      {
        path: "/basic/storagetemplate",
        meta: {
          info: "库存模板",
        },
        component: () =>
          import("@/views/basic/publictemplate/StorageTemplate.vue"),
      },
      {
        path: "/basic/shelftemplate",
        meta: {
          info: "货架模板",
        },
        component: () =>
          import("@/views/basic/publictemplate/ShelTemplate.vue"),
      },
      {
        path: "/basic/assettemplate",
        meta: {
          info: "资产模板",
        },
        component: () =>
          import("@/views/basic/publictemplate/AssetTemplate.vue"),
      },
      // 定制化模块
      {
        path: "/asset/dzpd",
        meta: {
          info: "区域组",
        },
        component: () => import("@/views/customized/DzpdView.vue"),
      },
      {
        path: "/asset/dzpdrecord",
        meta: {
          info: "盘点记录",
        },
        component: () => import("@/views/asset/PanDian.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  // from
  // document.title = to.meta.info;
  if (
    to.path != "/login" &&
    to.path != "/nopermission" &&
    to.path != "/register" &&
    to.path != "/UserInformation" &&
    !to.path.includes("/reception") &&
    !to.path.includes("/DataCenter") &&
    !to.path.includes("/basic/approve") &&
    !to.path.includes("/assetoverview") &&
    !to.path.includes("/storageoverview") &&
    !to.path.includes("/luckysheet") &&
    !to.path.includes("/editsheet")
  ) {
    CheckMenuAuthority({ url: to.path }).then((res) => {
      if (res.Code == 0) {
        next();
      } else if (res.Code != -1) {
        next("/nopermission");
      }
    });
  } else {
    next();
  }
});

export default router;
