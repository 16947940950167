<template>
  <el-dialog :visible.sync="state" :title="$t('BasicDept.SelectRegion')" width="1000px" height="660px" @close="close" custom-class="_dialog_height" append-to-body>
    <div class="settin-box">
      <div class="_right">
        <div class="content_left" v-loading="loading2"> 
          <el-input :placeholder="$t('StorageCategory.PleaseEnter')" v-model="filterText">
          </el-input>
          <el-tree class="treeTable" :data="data" node-key="id" ref="tree"  :expand-on-click-node="false" :filter-node-method="filterNode"
            @node-click="handleNodeClick">
            <span class="custom-tree-node" slot-scope="{ node,data }">
              <el-tooltip :disabled="node.label.length<PDleng(node.label)" class="item" effect="dark" :content="node.label" placement="top-start">
                <span style="font-size: 15px;" :style="DistrictID == data.id ? 'color: rgb(22, 93, 255)' : ''">
                  {{ node.label | ellipsis(PDleng(node.label)) }}
                </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="content_right">
          <div class="table">
            <div class="head_line">
              <el-input type="text" :placeholder="$t('AdminUser.NameOrNumber')" v-model="form.SearchName">
                <i class="el-icon-search" slot="suffix" @click="GetRegion()" style="line-height: 3;">
                </i>
              </el-input>
              <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
                  {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
            </div>
            <div class="content_table">
              <el-table @select-all="selectAll" @select="select" border :data="tableData" v-loading="loading" height="388px" stripe @selection-change="handleSelectionChange"
                ref="tableList" :row-class-name="selectRowClassName" @row-click="handleRowClick" @header-dragend="headerDragend">
                <el-table-column type="selection" width="55"> </el-table-column>

                <el-table-column :sortable="item.sort" v-for="(item, index) in TableList" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
                  <template slot-scope="scope">
                    <span v-if="item.propName == 'DistrictStatusName'"
                      :style="`color:${scope.row.PublicFormFontColor};padding: 20px 10px;border-radius: 2px;background-color:${scope.row.PublicFormBgColor}`">{{ scope.row.DistrictStatusName}}
                    </span>
                    <span v-else>
                      {{ scope.row[item.propName] }}
                    </span>
                  </template>
                </el-table-column>
                <div slot="empty" class="empty">
                  <img src="@/assets/img/empty.png" />
                  <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
                </div>
              </el-table>
            </div>
            <div class="component_footer">
              <el-pagination v-if="tableData" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(form.PageIndex + 1)"
                :page-sizes="[20, 50, 100]" :page-size="form.PageSize" layout="total, prev, pager, next, jumper,sizes" :total="TotalCount">
              </el-pagination>
            </div>
            <el-divider></el-divider>
            <div class="_button">
              <el-button class="button_info" @click="close">{{ $t('AssetAllotAdd.quit')}}</el-button>
              <el-button class="button_primary" @click="append()"> {{ $t('AssetAllotAdd.query') }} </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DragTable v-if="TableOk" TableName="AssetDistrictComponents" :show="TableOk" @message="Message_">
    </DragTable>
  </el-dialog>
</template>
  
<script>
import {
  GetAssetDistrictPage,
  GetAssetDistrictTree,
  LoadBasicUserColumn,
  SetBasicUserColumnWidth,
  updateTime,
} from "@/api/user";
import mixins from "@/mixins";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  components: {},
  mixins: [mixins],
  filters: {
    ellipsis(value, len) {
      if (!value) return ''
      if (value.length > len) {
        return value.slice(0, len) + '...'
      }
      return value
    },
  },
  computed: {
    state: {
      get() {
        return this.type;
      },
      set() {

      }
    }
  },
  props: {
    type: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    DataList: {
      type: Array,
      default() {
        return []
      }
    },
    seltype: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      TotalCount: 0,
      pageNumber: 0,
      UserRoomShow: false,
      loading2: false,
      addUserImg: '',
      tabs: 0,
      menutab: 0,
      ID: "",
      level: "",
      userState: '',
      userText: this.$t('AdminUser.DisOrEn'),
      filterText: "",
      drawer: false,
      icon: '',
      UserTitle: '',
      companyChecked: false,
      dialogFormVisible: false,
      userChecked: false,
      TableOk: false,
      checked: true,
      passWordChecked: false,
      list: [this.$t('AssetDistrict.District')],
      title: this.$t('AdminUser.AllUser'),
      currentPage: 1,
      value: '',
      DeptTitle: "",
      DistrictID: '',
      tableData: [],
      form: {
        PageIndex: 0,
        PageSize: 20,
        SearchName: '',
        DistrictID: ''
      },
      BasicUserList: [],
      loading: false,
      tabslist: [
        {
          title: this.$t('AdminUser.AllUser'),
          icon: "el-icon-user",
        },
        {
          title: this.$t('AdminUser.offUser'),
          icon: "el-icon-user",
        },
      ],
      data: [],
      updatetype: false,
      oldImg: '',
      imageNum: 0,
      UserID: '',
      multipleSelection: [],
      deptObj: {},
      upfile: null,
      TableList: [],
      CDeptID: [],
      DeptIDList: []
    };
  },
  mounted() {
    this.state = this.type;
    this.getTableColumn();
  },
  methods: {
    select(selection) {
      if (selection.length > 1 && this.seltype == true) {
        let del_row = selection.shift()
        this.$refs.tableList.toggleRowSelection(del_row, false)
      }
    },
    selectAll(selection) {
      if (selection.length > 1 && this.seltype == true) {
        selection.length = 1
      }
    },
    PDleng(data) {
      if (/[\u4e00-\u9fa5]/g.test(data) == true) {
        return 8;
      } else {
        return 20;
      }
    },
    show() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.DataList.length; j++) {
            if (this.tableData[i].DistrictID == this.DataList[j]) {
              this.$refs.tableList.toggleRowSelection(this.tableData[i]);
            }
          }
        }
      }, 1000)
    },
    //点击获取节点数据
    handleNodeClick(data) {
      this.DistrictID = data.id;
      this.GetRegion();
    },

    //获取组织架构树状图
    getData() {
      this.loading2 = true;
      GetAssetDistrictTree().then((res) => {
        if (res.Code == 0 && res.Data != null) {
          let obj = { pid: 0, id: 0, label: this.$t('AssetDistrict.AllDistrict'), level: 1, status: 1, children: [] };
          res.Data.unshift(obj);
          this.data = res.Data;
          if (res.Data.level == 1) {
            if (res.Data.children == []) {
              this.icon = "el-icon-school";
            } else {
              this.icon = "el-icon-office-building";
            }
          }
          this.loading2 = false;
        } else {
          this.loading2 = false;
        }
      });
    },
    GetRegion() {
      this.form.DistrictID = this.DistrictID;
      if (this.form.DistrictID == "" || this.form.DistrictID == null) {
        this.form.DistrictID = 0;
      }
      GetAssetDistrictPage(this.form).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.tableData = res.Data;
          this.TotalCount = res.TotalCount;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].CreateTime = updateTime(this.tableData[i].CreateTime);
          }
        } else {
          this.tableData = [];
          this.TotalCount = 0;
        }
        this.show();
      });
    },
    handleSizeChange(val) {
      this.form.PageSize = val; //每页数量
      this.GetRegion();
    },
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);//第几页
      this.GetRegion();

    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    close() {
      this.$emit('close')
    },
    append() {
      if (!this.seltype) {
        this.$emit('addMembers', this.BasicUserList);
      } else {
        this.$emit('addMember', this.BasicUser);
      }
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    resetting() {
      //重置
      this.clearImg();
      for (let i in this.BasicUser) {
        this.BasicUser[i] = '';
      }
    },
    Search() { },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.DistrictID == row.DistrictID) {
          color = "warning-row";
        }
      });
      return color;
    },
    //选择整行
    handleRowClick(row) {
      this.BasicUser = row;
      if (this.seltype) {
        this.$refs.tableList.clearSelection()
      }
      let index = this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.DistrictID == row.DistrictID
      });
      if (index == -1) {
        // 如果未存在，设置已选状态，并在list中添加这条数据
        this.$refs.tableList.toggleRowSelection(row, true); //设置复选框为选中状态
      } else {
        // 如果已存在，设置未选状态，并在list中删除这条数据
        this.$refs.tableList.toggleRowSelection(row, false); //设置复选框为未选状态
      }
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'AssetDistrictComponents' }).then(res => {
        if (res.Code == 0) {
          this.TableList = res.Data;
          this.getData();
          this.GetRegion();
        }
      })
    },
    //刷新
    Message_() {
      this.getTableColumn();
      this.getData();
      this.TableOk = false;
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'AssetDistrictComponents', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    handleSelectionChange(val) {
      // this.BasicUser = val;
      this.multipleSelection = JSON.parse(JSON.stringify(val))
      // 获取选中点击的数据 
      if (this.seltype) {
        if (val.length > 0) {
          this.BasicUser = val[0];
          this.checked = false;
          this.UserID = val[0].UserID;
        } else {
          this.checked = true;
        }
      } else {
        if (val) {
          this.BasicUserList = val;
          this.checked = false;
        } else {
          this.checked = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
._button {
  display: flex;
  justify-content: end;
}
::v-deep .component_footer {
  align-items: end !important;
}
.el-pagination {
  justify-content: end !important;
}
::v-deep .component_footer {
  align-items: end !important;
}
.el-divider.el-divider--vertical {
  height: 583px !important;
}
.but_add {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog__body {
  height: 600px !important;
}
.el-divider.el-divider--vertical {
  height: 500px;
  margin-top: 25px;
  margin-left: 5px;
}

.el-dialog__title {
  font-size: 16px;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #c00;
  border-radius: 50%;
  /* 小圆点 */
  margin-right: 12px;
  /* 和文本之间的距离 */
  vertical-align: middle;
  /* 垂直居中 */
}

::v-deep .el-tree-node__content {
  margin-top: 10px;
}

::v-deep ._dialog_height {
  width: 1000px !important;
  height: 655px !important;
}

._footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

.treeTable {
  overflow: auto;
  max-height: 455px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon-plus {
  border: 1px dashed;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

._filter {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;
  }
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.avatars {
  width: 135px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 1;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: -42px;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  width: 135px;
  height: 128px;
  display: block;
  margin-top: -38px;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 600px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  margin-top: -20px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;
    margin-top: -20px;

    .content_left {
      width: 30%;
      height: 604px;
      padding: 16px 20px;
      font-size: 19px;
      margin-left: -18px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 0px 10px;

        span {
          font-size: 14px;
          max-width: 50px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        .cy li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          &:hover {
            background-color: #409eff;
            color: #fff;
            cursor: pointer;
          }

          span {
            margin-left: 10px;
          }
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .companyimg {
      line-height: 150px;
    }

    .content_right {
      height: calc(100vh - 700px);
      position: absolute;
      width: 70%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 50px;
        .el-input {
          width: 250px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}
</style>