<template>
  <el-dialog :title="Title" :visible.sync="dialogFormVisible" width="803px" height="594px" lock-scroll append-to-body @close="close()">
    <el-table row-key="id" ref="dialogList" border :data="routeprocessList " height="460px">
      <el-table-column type="index" width="55" :label="$t('StorageInKu.NumBer')"> </el-table-column>

      <el-table-column prop="ColumnName" :label="$t('StorageInKu.Columnname')" width="auto">
        <template slot-scope="scope">
          <span>
            {{ scope.row.ColumnName }}
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="$store.state.userinfo.UserType != 2" prop="ColumnName2" :label="$t('StorageInKu.NewInput')" width="auto">
        <template slot-scope="scope">
          <el-input :placeholder="$t('StorageInKu.NewInput')" class="_table_input" v-model="scope.row.NewColumnName" :data-rowindex="'NewColumnName' + scope.$index"
            :ref="`inputRef${scope.$index + 1}`" @keydown.tab.native="focusTabInput(scope.$index + 1)"
            @keyup.enter.tab.native="focusTabInput(scope.$index + 1, 'NewColumnName')"></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="ColumnName" :label="$t('StorageInKu.Isdisplayed')" width="auto">
        <template slot-scope="scope">
          <el-radio v-model="scope.row.ColumnStatus" :label="1">{{$t('StorageInKu.display')}}</el-radio>
          <el-radio v-model="scope.row.ColumnStatus" :label="2">{{$t('StorageInKu.hide')}}</el-radio>
        </template>
      </el-table-column>

      <el-table-column prop="ColumnName" :label="$t('StorageInKu.Columnname')" width="auto">
        <template slot-scope="scope">
          <el-input :placeholder="$t('StorageInKu.InputWidth')" class="_table_input" v-model="scope.row.ColumnWidth" :data-rowindex="'ColumnWidth' + scope.$index"
            :ref="`inputRef${scope.$index + 1}`" @keydown.tab.native="focusTabInput(scope.$index + 1)"
            @keyup.enter.tab.native="focusTabInput(scope.$index + 1, 'ColumnWidth')"></el-input>
        </template>
      </el-table-column>

    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button class="button_info" @click="Reset()" style="margin-right: 7px">{{$t('BasicDept.Reset')}}</el-button>
      <div>
        <el-button class="button_info" @click="dialogFormVisible = false" style="margin-right: 7px">{{$t('StorageGoods.cancel')}}</el-button>
        <el-button class="button_primary" type="primary" @click="append()"> {{$t('StorageGoods.confirm')}} </el-button>
      </div>
    </div>
  </el-dialog>
</template> 
      <script> 
import {
  GetBasicUserColumn,
  SetBasicUserColumn,
  DeleteUserColumn,
  successTips
} from "@/api/user";
import Sortable from 'sortablejs'
export default {
  props: {
    GoodsData: {
      type: Array,
      default() {
        return [];
      }
    },

    TableName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
      default: () => {
        return false
      }
    },
  },
  data() {
    return {
      disabledChecked: false,
      dialogFormVisible: false,
      loading: false,
      goodsChecked: false,
      Title: this.$t('StorageInKu.CustomList'),
      selectTable: [],
      ColumnData: [],
      routeprocessList: [],
      Data: [],
      DetailList: '',
      dropCol: [
        {
          labelName: this.$t('StorageInKu.Columnname'),
          propName: "ColumnName",
          sort: true,
          width: "auto",
          ColumnStatus: 1
        },
        {
          labelName: this.$t('StorageInKu.alias'),
          propName: "ColumnName2",
          sort: true,
          width: "auto",
          ColumnStatus: 1
        },
        {
          labelName: this.$t('StorageInKu.Isdisplayed'),
          propName: "ColumnName3",
          sort: true,
          width: "auto",
          ColumnStatus: 1
        }, {
          labelName: this.$t('StorageInKu.width'),
          propName: "ColumnName4",
          sort: true,
          width: "auto",
          ColumnStatus: 1
        }

      ]
    };
  },
  mounted() {
    this.FaKaStorageHuoPin = this.FaData;
    this.dialogFormVisible = this.show;
    console.log(this.dropCol);
    this.getTable(this.TableName);
    setTimeout(() => {
      this.rowDrop();
    }, 1000)
    document.addEventListener('keydown', this.keyprevent);
  },
  methods: {
    append() {
      let form = {
        TableKey: this.routeprocessList[0].TableKey,
        DetailList: "",
      };

      let str = [];
      for (let item of this.routeprocessList) {
        let { OriginalColumnID, ColumnStatus, ColumnWidth, NewColumnName, ColumnKey } = item;
        str.push({
          OriginalColumnID,
          ColumnStatus,
          ColumnWidth,
          NewColumnName,
          ColumnKey
        });
      }
      form.DetailList = JSON.stringify(str);
      SetBasicUserColumn(form).then((res) => {
        if (res.Code == 0) {
          this.$emit('message', res.Code);
          this.dialogFormVisible = false;
          successTips(res.Message);
          this.close();
        }
      });
    },
    //重置
    Reset() {
      DeleteUserColumn({ UserID: this.$store.state.userinfo.UserID, TableKey: this.routeprocessList[0].TableKey }).then((res) => {
        if (res.Code == 0) {
          this.$emit('message', res.Code);
          this.dialogFormVisible = false;
          successTips(res.Message);
          this.close();
        }
      });
    },
    rowDrop() {
      const table = this.$refs.dialogList.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
      const that = this
      Sortable.create(table, {
        animation: 1000,
        onEnd({ newIndex, oldIndex }) {
          // 拖拽排序数据
          that.routeprocessList.splice(newIndex, 0, that.routeprocessList.splice(oldIndex, 1)[0])
          const newArray = that.routeprocessList.slice(0)
          that.routeprocessList = [] // 必须有此步骤，不然拖拽后回弹
          that.$nextTick(function () {
            that.routeprocessList = newArray // 重新赋值，用新数据来刷新视图
            this.updateIndex(that.routeprocessList)//更改列表中的序号，使序号1.2.3.4.....显示，不然就是行拖拽后乱序显示如:3.2.4.1...
          })
        }
      });
    },
    updateIndex(routeprocessList) {
      routeprocessList.forEach((item, index) => {
        item.orderNum = index + 1;
      });
    },
    keyprevent(event) {
      if (event.keyCode === 9) {
        event.preventDefault()
      }
    },
    focusTabInput(rowIndex) {
      this.$nextTick(() => {
        this.$refs.dialogList.clearSelection();
        if (rowIndex < this.routeprocessList.length) {
          this.$refs[`inputRef${parseInt(rowIndex + 1)}`][0].focus();
          this.$refs.dialogList.toggleRowSelection(this.routeprocessList[rowIndex]);
        } else {
          this.$refs[`inputRef1`][0].focus();
          this.$refs.dialogList.toggleRowSelection(this.routeprocessList[0]);
        }
      })
    },
    getTable(e) {
      GetBasicUserColumn({ TableKey: e }).then(res => {
        if (res.Code == 0) {
          this.routeprocessList = res.Data;
          for (let i = 0; i < this.routeprocessList.length; i++) {
            if (this.routeprocessList[i].NewColumnName == "") {
              this.routeprocessList[i].NewColumnName = this.routeprocessList[i].ColumnName;
            }
          }
        }
      })
    },


    // dialog中的货品列表选中数据
    handleSelectionChange1(val) {
      if (val.length != 0) {
        this.goodsChecked = false;
        this.selectTable = val;
      } else {
        this.goodsChecked = false;
      }
    },

    //回车换行
    focusNextInputTow(rowIndex, columnName) {
      this.$nextTick(() => {
        let nextInput = null
        if (rowIndex == this.ColumnData.length) {
          nextInput = document.querySelector(`[data-rowindex="${columnName}0"]`)
        } else {
          nextInput = document.querySelector(
            `[data-rowindex="${columnName}${rowIndex}"]`
          )
        }
        if (nextInput) {
          nextInput.focus()
        }
      })
    },

    // 关闭
    close() {
      this.dialogFormVisible = false;
      this.$emit('message', 1);
      this.$emit("close");
    },
  },
};
    </script> 
<style lang="scss" scoped>
 
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #0d69fe !important;
  background: #0d69fe !important;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #0d69fe !important;
}
/deep/ .el-table .cell {
  height: 42px;
  line-height: 42px;
  text-align: center;
}
._table_input {
  /deep/.el-input__inner {
    text-align: center; 
  }
}
::v-deep ._table_input.el-input {
  padding: 0px !important;
}
::v-deep .el-dialog__body {
  margin-bottom: 20px !important;
  padding: 5px 20px !important;
}
::v-deep .el-dialog__footer {
  margin-top: -6px !important;
}
::v-deep .el-dialog {
  height: 594px;
}
.SearchName {
  margin-right: 0% !important;
}

.el-input {
  width: 75% !important;
}

.el-select {
  width: 75% !important;
  margin-right: 8%;
}

.textarea {
  width: 75% !important;
}

/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}

.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
[data-v-6166d673] input.el-input__inner {
  height: 29px !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  margin-top: -40px;
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .avatars {
      width: 135px;
      height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      line-height: 1;
      background-color: #eee;
      border: 1px solid #ccc;
      cursor: pointer;

      &:hover {
        background-color: #fffefe;
      }
    }

    .avatar {
      width: 135px;
      height: 128px;
      display: block;
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

::v-deep .el-dialog__body {
  height: 460px !important;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
  // margin-bottom: 50px;
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  span i {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.filter_box {
  padding: 0 30px 0 0;

  ul {
    padding-left: 10px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .date {
      // margin-left: 45px;
      width: 300px;

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      /deep/.el-input {
        width: 300px !important;

        .el-input__inner {
          width: 300px !important;
        }
      }
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #fff;
  }

  .el-select {
    width: 300px;
  }

  .el-input {
    width: 300px;
  }

  ._filter_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}
</style>