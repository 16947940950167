<template>
  <el-drawer :title="$t('StorageInKuDetail.ChangeTitle')" :visible.sync="shows" direction="rtl" size="1000" @close="close">
    <div class="_info">
      <div class="detail_info">
        <p class="title">{{ $t('StorageInKuDetail.ChangeDetailed') }}</p>
        <div class="content">
          <el-skeleton :rows="4" :loading="DataState == true" animated>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageChange.TiaoZDanCode') }}:</span> <!-- 调整编号-->
                <font>{{ model.TiaoZDanCode }}</font>
              </p>
              <p>
                <span>{{ $t('StorageChange.WarehouseName') }}:</span><!-- 库房名称-->
                <font v-if="$getBytes(model.WarehouseName) < 40">{{ model.WarehouseName }}</font>
                <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.WarehouseName">
                  <font slot="reference">{{ model.WarehouseName }}</font>
                </el-popover>
              </p>
            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageChange.TiaoZTime') }}:</span><!-- 调整时间-->
                <font>{{ model.TiaoZTime }}</font>
              </p>
              <p>
                <span>{{ $t('StorageInKuDetail.CreateTime') }}:</span><!-- 创建时间-->
                <font>{{ model.CreateTime }}</font>
              </p>
            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageChange.Operator') }}:</span><!-- 经办管理人-->
                <font v-if="$getBytes(model.Operator) < 40">{{ model.Operator }}</font>
                <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.Operator">
                  <font slot="reference">{{ model.Operator }}</font>
                </el-popover>
              </p>
              <p>
                <!-- 调整状态-->
                <span>{{ $t('StorageChange.TiaoZStatus') }}:</span>
                <font :style="`color:${this.model.TiaoZStatusColor}`">
                  {{ this.model.TiaoZStatusName }}
                </font>
              </p>

            </div>
            <div class="content_item">
              <p>
                <span>{{ $t('StorageChange.TiaoZRemark') }}:</span><!-- 调整描述-->
                <font v-if="$getBytes(model.TiaoZRemark) < 40">{{ model.TiaoZRemark }}</font>
                <el-popover v-else placement="top-start" width="200" trigger="hover" :content="model.TiaoZRemark">
                  <font slot="reference">{{ model.TiaoZRemark }}</font>
                </el-popover>
              </p>
            </div>
          </el-skeleton>
        </div>
      </div>

      <div style="display: flex;align-items: center;justify-content: space-between;margin: 20px 0;">
        <span style="font-size: 14px;color:#4e5969;font-weight: bold;">{{ $t('StorageInKuDetail.GoodsDetail') }}</span>
        <div>
          <span style="color: #3260f6;cursor: pointer;">
            <i class="el-icon-download"></i>
            <a style="color:rgb(50, 96, 246)" :href="downUrl">{{ $t('StorageInKuDetail.DownLoadDetail') }}</a>
          </span>
          <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
              {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
        </div>
      </div>
      <el-table v-loading="loading" :key="itemKey" border :data="list" height="calc(100vh - 400px)" @header-dragend="headerDragend">
        <el-table-column type="index" width="55" :label="$t('StorageAllotDetail.No')"> </el-table-column>
        <el-table-column :sortable="item.sort" v-for="(item, index) in GoodsTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
          <template slot-scope="scope">
            <ImageView v-if="item.propName =='HuoPinImage'" :url="scope.row.GoodsImage||scope.row.HuoPinImage"></ImageView>
            <el-input v-else-if="item.propName == 'AllotCount'" oninput="value=value.replace(/[^\d]/g,'')" class="_table_input" v-model="scope.row.AllotCount"
              :data-rowindex="'AllotCount' + scope.$index" :ref="`inputRef${scope.$index + 1}`" @keydown.tab.native="focusTabInput(scope.$index + 1)"
              @input="checkOutCount(scope.$index, $event)" @keyup.enter.native="focusTabInput(scope.$index + 1, 'AllotCount')"></el-input>
            <span v-else>
              {{ scope.row[item.propName] }}
            </span>
          </template>
        </el-table-column> 
      </el-table>
      <el-row style="height: 50px; padding-top: 10px" v-if="loading == false">
          <el-col :span="20">
            {{ $t('StorageChange.OldCount') }} {{ model.AllCount }}
            {{ $t('StorageChange.OldPrice') }} {{ model.AllMoney }}
          </el-col>
        </el-row>
    </div>
    <DragTable v-if="TableOk" TableName="StorageTiaoZDetail" :show="TableOk" @message="Message_">
    </DragTable>
  </el-drawer>
</template>
  
  <script>
import {
  SetBasicUserColumnWidth,
  LoadBasicUserColumn
} from "@/api/user";
export default {
  props: {
    form: {
      type: Object
    },
    Notes: {
      type: Array,
      default() {
        return []
      }
    },
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      downUrl: '',
      itemKey: '',
      model: {},
      list: [],
      GoodsTable: [],
      DataState: true,
      shows: false,
      loading: false,
      TableOk: false
    }
  },

  mounted() {
    this.getTableColumn();
    this.model = this.form;
    this.loading = true;
    for (let item in this.model) {
      if (item.toUpperCase().includes('TIME')) {
        this.model[item] = this.$transferTime(this.model[item]);
      }
    }
    let lang = localStorage.getItem("locale") || "zh_CN";
    this.downUrl = this.$url + '/StorageTiaoZDan/TiaoZDownExcel?code=' + this.form.TiaoZDanID + '&UserID=' + this.$store.state.userinfo.UserID + '&Language=' + lang;
    setTimeout(() => {
      this.list = this.Notes;
      if (this.model) {
        this.DataState = false;
      } else {
        this.DataState = false;
      }
      this.loading = false;
    }, 1000)
    this.shows = this.show;
  },
  methods: {
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'StorageTiaoZDetail', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'StorageTiaoZDetail' }).then(res => {
        if (res.Code == 0) {
          this.GoodsTable = res.Data;
        }
      })
    },
    //刷新
    Message_() {
      this.list = this.Notes;
      this.getTableColumn();
      this.itemKey = Math.random();
      this.TableOk = false;
    },
    close() {
      this.$emit('close')
    }
  }
}
  </script>
  
  
  <style lang="scss" scoped>
.detail_info .content .content_item {
  height: 30px !important;
}
/deep/ .content_item {
  height: 30px !important;
}
span.Refresh {
  margin-left: 15px;
}
.el-dialog__title {
  font-size: 16px;
  color: #1d2129;
}

span.el-dialog__title {
  margin-left: 8px;
}

.content_item {
  margin-top: 12px;
}

.el-dialog__body {
  padding: 0px 32px;
}

.Download {
  margin-bottom: 17px;
}

.detail_info {
  margin-bottom: 25px;
}

.textarea {
  width: 75%;
}

a {
  text-decoration: none;
  color: #409eff;
}

/deep/.el-drawer {
  width: 1000px;
}
</style>